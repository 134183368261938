<template>
  <div class="qsdiv">
    <van-pull-refresh v-model="loading_b" @refresh="onRefresh" class="center" id="div">
    <div>
      <div class="center_top">
        <div>{{askname}}</div>
        <img
          src="https://xcx.newacademic.net/video/largeImg/u15.png"
          @click="tab"
          mode="heightFix"
        /> 
      </div>
      <div v-for="(item,index) in qsdata" :key="index"> 
        <div v-if="item.type == 'askqs'" class="askqs">
          <div class="askqs_left">
            <div class="askqs_left_box">
              <div>{{item.content}}</div>
            </div>
          </div> 
          <div class="askqs_right"> 
            <img src="../../assets/imgs/user.png" />
          </div>
        </div> 
        <div v-else class="answer">
          <div class="answer_left">
            <img :src="role[item.activerole].image" />
          </div>
          <div class="answer_right">
            <div class="answer_right_box">
              <div v-html="item.content"></div>
              <i v-show="item.showloading" class="el-icon-loading" style="color:#6453D1;"></i>
              <p style="text-align:right;color:#6453D1;" v-show="!item.showloading && !item.isfrist">
                <i class="el-icon-document-copy" @click="copy(item.content)"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
   </van-pull-refresh>
    <div class="stopask" v-if="showloading" @click="stop_">
      <!-- 停止回答按钮 -->
      <img src="../../assets/imgs/停止.png" alt="">
    </div>
    <div class="fixedbottom">
      <img
        v-if="talkshow == false"
        class="microphone"
        src="https://xcx.newacademic.net/video/largeImg/microphone.png"
        @click="talk"
      />
      <img
        v-if="talkshow == true"
        class="microphone"
        src="https://xcx.newacademic.net/video/largeImg/microphone.png"
        @click="talkclose"
      />
      <div class="fixed_right" v-if="talkshow == false">
        <input
          style="width:100%;"
          type="text"
          v-model="content"
          placeholder="请输入您的问题"
          bindinput="changeinput"
        />
        <img src="https://xcx.newacademic.net/video/largeImg/send.png" alt="" @click="sendmessage">
      </div>
      <div
        class="fixed_right_talk"
        v-if="talkshow == true"
        @mousedown="handleStart" @mouseup="handleStop" @touchstart="handleStart()" @touchend="handleStop()"
      >
        按住 说话
      </div>
    </div>
    <div class="status-box" v-if="isRecording">
      <p>{{textarea?textarea:'正在说话...'}}</p>
    </div>
    <van-action-sheet v-model="popup" title="选择角色" @close="onClose">
        <div>
            <div v-for="(item,index) in role" class="rolelist" :key="index">
                <div class="rolelist_left">
                    <div class="rolelist_left_iamge">
                        <img :src="item.image"/>
                    </div>
                    <div class="rolelist_left_text">
                        <div class="rolelist_left_text_title">{{item.roles}}</div>
                        <div class="rolelist_left_text_slogan">
                            <div v-for="(items,indexs) in item.tags" wx:key="index" :class="'tags'+indexs" :key="indexs">
                                {{items}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rolelist_right">
                    <div v-if="activerole !== index" @click="getrole(item,index)">选择</div>
                    <div v-if="activerole == index" class="disable">已选择</div>
                </div>
            </div>
        </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Recorder from 'js-audio-recorder'
import $ from "jquery";
import marked from 'marked';
import axios from 'axios'
var that 
export default {
  data() {
    return {
      qsdata: [
        {
            type:'answer',
            content:'您好，我是AI智能问答助手。我可以为您提供常识性问题解答、学术知识普及、日常管理建议，以及社交对话等多种智能服务。无论您有什么疑问或需求，都欢迎随时向我提问，我将竭尽所能，为您提供准确的回答和帮助。',
            activerole:0,
            isfrist:1
        }
      ],
      askname:'智能问答助手',
      role: [
        {
          image: "https://xcx.newacademic.net/video/largeImg/kf.png",
          roles: "智能问答助手",
          slogan: "您好，我是AI智能问答助手。我可以为您提供常识性问题解答、学术知识普及、日常管理建议，以及社交对话等多种智能服务。无论您有什么疑问或需求，都欢迎随时向我提问，我将竭尽所能，为您提供准确的回答和帮助。",
          tags: ["广域知识", "深度理解", "实时应答"],
          type:"zhipuai"
        },
        {
          image: "https://xcx.newacademic.net/video/largeImg/ls.png",
          roles: "英文词汇大师",
          slogan: "您好，我是英文词汇大师。您可以输入任何英文单词或短语，我将为您提供音标、中文翻译、英文释义、词根词源、助记，并呈现三个例句。无论需要查询什么词汇，都欢迎随时向我提问。我将竭尽所能，为您提供准确详细的信息。",
          tags: ["单词注释", "快速助记", "应用造句"],
          type:"dictionary"
        }, 
        {
          image: "https://xcx.newacademic.net/video/largeImg/lvs.png",
          roles: "论文摘要润色助手",
          slogan: "您好，我是论文摘要润色助手，您可以输入一段论文的摘要，我会以论文评审员的角度去修改，使其更加流畅，优美。欢迎随时将摘要内容分享给我，我将竭尽所能为您提供帮助，以确保您的论文摘要达到最佳的表达效果。",
          tags: ["自动精炼", "智能改写", "结构检查"],
          type:"polish"
        },
        {
          image: "https://xcx.newacademic.net/video/largeImg/lxj.png",
          roles: "时间旅行家",
          slogan: "您好，我是时间旅行家。您可以输入任何一个历史时期或具体年份，我都会为您推荐在相应时间发生的一些有趣事件、著名景点和代表人物，让您感受到时间旅行的魅力，并了解那个特定时期的重要元素", 
          tags: ["时空探索", "历史漫游", "信息获取"],
          type:"guidebook"
        },
      ],
      activerole: 0,
      popup: false,
      content: "",
      talkshow: false,
      isRecording: false,
      tempFilePath: "",
      wavFilePath: "",
       //   录音
       recorder:null,
      time:null,
      speakId:null,
      can:true,
      fileOfBlob:null,
      inputLoading:false,
      chattoken:'',
      returnMessageAjax:null,
      showloading:false, 
      asktype:'zhipuai',
      // askurl:'http://192.168.66.105:8005/api/',
      askurl:'https://xcx.newacademic.net/api/',
      // askurl:'https://ql-test.newacademic.net/api/',
      // askurl:window.location.origin+'/api/',
      recordlist:[],
      page:1,
      total:0,
      loading_b:false,
      textarea:'',
    };
  },
  created(){
    // 從路由上獲取參數
    if(this.$route.query.token){
        this.chattoken = this.$route.query.token
    }
    that = this
    // this.getawsnlist() 
    
  },
  methods:{
    copy(copytext){
      // 复制文字
        // 创建一个临时的textarea元素
        
        var text = copytext.replace(/<\/?[^>]+>/g, '')
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      this.$notify({
        message: '已复制文本信息',
        type: 'success'
      })
      // 选择并复制文本
      textarea.select();
      document.execCommand('copy');

      // 清理临时元素
      document.body.removeChild(textarea);
    },
    onRefresh() {
        this.getawsnlist()
    },
    getrole(e,i){
        this.qsdata = []
        var order = {
            type:'answer',
            content:e.slogan,
            activerole:i,
            isfrist:1
        }
        var newlist = this.qsdata.concat(order)
        this.askname = e.roles
        console.log(e);
        this.asktype = e.type
        this.activerole = i
        this.qsdata = newlist
        this.popup = false

    },
    onClose() {
        this.popup = false;
    },
    handleStop(type) {
      let that = this
      
      if(that.recorder !== null && that.can){
        that.recorder.stop() // 停止录音
        
        if(type == 'leave'){
            return
        }else{
            that.recorder.stop() // 停止录音
            const blob = that.recorder.getWAVBlob()// 获取wav格式音频数据
            // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
            const newbolb = new Blob([blob], { type: 'audio/wav' })

            that.fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
            
            let fD = new FormData();
            fD.append('file_obj', that.fileOfBlob, 'audio.wav');
            // this.inputLoading = true
            that.textarea = '语音识别中...'
            this.talkshow = false
            axios.post(that.askurl + 'extend/language_to_text/', fD, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'token': that.chattoken
              }
            }).then(res=>{
              
              that.isRecording = false
              this.content = res.data.data
              
            })
            
        }
      }
    },
    handleStart(t) {
        let that = this
        that.isRecording = true
        this.recorder = new Recorder()
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function(stream) {
                    console.log('开始录音', that.recorder)
                    console.log(stream);
                    that.can = true
                    Recorder.getPermission().then(() => {
                        that.textarea = ''
                        that.recorder.start() // 开始录音
                        that.recorder.onprogress = function(params) {
                            that.time = params.duration
                        }
                        
                    }, (error) => {
                        this.$notify({
                        message: '请先允许该网页使用麦克风',
                        type: 'danger'
                        })
                        console.log(`${error.name} : ${error.message}`)
                    })
                })
                .catch(function(err) {
                    that.can = false
                    that.$notify({
                        message: '您的浏览器不支持语音识别',
                        type: 'warning'
                        })
                    console.log(err);
                });
        }else {
            mystatus.innerHTML = '您的浏览器不支持识别声音大小';
        }
    },
    tab(){
      if(this.showloading == true){
        this.$notify({
          message: '正在查询中，请稍后再试',
          type: 'warning'
        })
      }else{
        this.popup=true
      } 
    },
    talk(){
        this.talkshow = true
    },
    talkclose(){
        this.isRecording = false
        this.talkshow = false
    },
    getawsnlist(){
      if(this.total != 0){
        if(this.page * 10 > this.total){
          this.loading_b = false
          this.$notify({
            message: '没有更多数据了',
            type: 'warning'
          })
          return
        }
      }
      console.log(this.asktype);
      this.$http.get(this.askurl+'extend/question_answering_record/?page='+this.page+'&size=10&question_type='+this.asktype,{
        headers:{
            token: this.chattoken,
        }
      }).then(res=>{
        // 追加到数组头部
        var newlist = res.data.data.list.concat(this.recordlist)
        this.recordlist=  newlist
        var newlist = []
        this.recordlist.map(item =>{
          var newtext = marked(item.reply)    
          newtext = newtext.replace(/<p>/g, `<p style="line-height:1.5;">`);
          newtext = newtext.replace(/<pre/g, `<pre style="margin:10px 0;overflow: auto;background: #F4F6F8;padding: 10px 5px;border-radius:10px;"`);
          newtext = newtext.replace(/<code/g, `<code style="color: #333;line-height: 20px; border-radius: 5px; font-family: 'Courier New', monospace;"`);
          newtext = newtext.replace(/<table/g, '<table class="table-bordered"');
          // 给str中的th tr标签添加class
          newtext = newtext.replace(/<th/g, '<th class="text-center gongong"');
          newtext = newtext.replace(/<td/g, '<td class="gongong"');
          newtext = newtext.replace(/<tr/g, '<tr class="text-tr"');
          newlist.push(
            {
            type: "askqs",
              content: item.question,
            },
            {
              type: "answer",
              content:  newtext,
              activerole: this.activerole,
              showloading:false
            }
          )
        })
        newlist = newlist.concat(this.qsdata)
        this.qsdata = newlist
        this.total = res.data.data.total
        this.loading_b = false
        this.page = this.page+1
      })
    },
    stop_(){
      this.returnMessageAjax.abort()
      this.qsdata[this.qsdata.length - 1].showloading = false
      this.showloading = false
    },
    sendmessage(){
        if(this.showloading == true){
          this.$notify({
            message: '正在查询中，请稍后再试',
            type: 'warning'
          })
            return
        }

        if(this.content == ''){
            this.$notify({
                message: '请输入您的问题',
                type: 'warning'
            })
            return
        }
        this.qsdata.push({
            type: "askqs",
            content: this.content,
          },{
            type: "answer",
            content: "正在查询中...",
            activerole: this.activerole,
            showloading:true
          })
        var json = {
            content:this.content,
            question_type:this.asktype
        }
        // 滚动到当前元素底部
        setTimeout(() => {
          var newdiv=window.document.getElementById("div")
          newdiv.scrollTop = newdiv.scrollHeight;
        }, 100);
        this.showloading = true
          this.returnMessageAjax = $.ajax({
            url: this.askurl+'extend/question_answering/',
            type: 'POST',
            data:JSON.stringify(json) ,
            headers:{
                "Content-Type": "application/json;charset=UTF-8",
                token: this.chattoken,
            },
            timeout: 50000, // 请求超时时间
            dataType: "json",
            xhrFields: {
                onprogress: function (e) {
                    let response = e.currentTarget.response;
                    console.log(response);
                    that.content = ''
                    // console.log(response);
                    if (response.includes('"code":') && response.includes('"msg":')) {
                    that.quit();
                    // 換成van-notify JSON.parse(response).msg
                    that.$notify({
                        message: JSON.parse(response).msg,
                        type: 'warning'
                    })
                    }
                    if (response.includes("[stop:0]")) {
                    let txt_arr = response.split("[stop:0]");
                    response = txt_arr[0] + txt_arr[1];
                    }
                    if (response.includes("[stop:1]")) {
                    let txt_arr = response.split("[stop:1]");
                    response = txt_arr[0] + txt_arr[1];
                    }
                    // console.log(response);
                    that.splicing_(response);
                    // that.time_(that.start_time, "start");
                    that.scrollBottm();
                    // returnMessageAjax.abort();
                },
                onload: function (e) {
                    console.log(e);
                },
            },
            complete: function(XMLHttpRequest, status) {
              if(XMLHttpRequest.readyState == 4){

              }
              if (status === "timeout") {
                alert("请求超时");
              } else if (status === "parsererror") {
                that.stop_();
              }
            }
            // success: (res) => {
            //     if(res.code == 200){
            //         this.qsdata.push({
            //             type: "answer",
            //             content: res.data,
            //             activerole: this.activerole,
            //           })
            //     }else{
            //         this.$notify({
            //             message: res.msg,
            //             type: 'warning'
            //         })
            //     }
            // },
            // error: (err) => {
            //     console.log(err);
            // }
        })
    },
    isScrollbarAtBottom(){
        const element = window.document.getElementById("div");
        var scrollHeight = element.scrollHeight;
        var clientHeight = element.clientHeight;
        var scrollTop = element.scrollTop;
        return scrollTop === scrollHeight - clientHeight;
      },
    // 滚动条到底部
    scrollBottm(e) {
        // 如果id为div的div滚动条不在底部 则不执行 给出代码
        if (!this.isScrollbarAtBottom()) {
          return;
        }
        
        var that = this;
        if(e){
          if(that.isScrollbarAtBottom()){
            setTimeout(function () {
              const el = window.document.getElementById("div");
              if (el.scrollHeight) {
                el.scrollTop = el.scrollHeight + 100;
              }
            }, 50);
          }
        }else{
          setTimeout(function () {
              const el = window.document.getElementById("div");
              if (el.scrollHeight) {
                el.scrollTop = el.scrollHeight + 100;
              }
            }, 50);
        }

        
    },
    splicing_(txt) {
      console.log(txt);
        var newtext = marked(txt)    
        console.log(newtext);
        newtext = newtext.replace(/<p>/g, `<p style="line-height:1.5;">`);
        newtext = newtext.replace(/<pre/g, `<pre style="margin:10px 0;overflow: auto;background: #F4F6F8;padding: 10px 5px;border-radius:10px;"`);
        newtext = newtext.replace(/<code/g, `<code style="color: #333;line-height: 20px; border-radius: 5px; font-family: 'Courier New', monospace;"`);
        newtext = newtext.replace(/<table/g, '<table class="table-bordered"');
        // 给str中的th tr标签添加class
        newtext = newtext.replace(/<th/g, '<th class="text-center gongong"');
        newtext = newtext.replace(/<td/g, '<td class="gongong"');
        newtext = newtext.replace(/<tr/g, '<tr class="text-tr"');
      if (newtext) {
            this.qsdata[this.qsdata.length - 1].content = newtext;
      }
    },
  }
};
</script>

<style>
html{
    min-width: none !important;
}
.stopask{
    position: fixed;
    bottom: 90px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}
.stopask>img{
    width: 100%;
    height: 100%;
}
.qsdiv{
    height: 100vh;
    width: 100%;
    background:#F1F1F1 ;
}
.center{
    height: calc(100vh - 75px);
    max-height: calc(100vh - 75px);
    overflow: auto;
    padding-bottom: 10px;
    padding-top: 55px;
}
.center_top{
    position: fixed;
    top: 0;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background:#F1F1F1 ;
}
.center_top>div{
    height: 35px;
    border: 2px solid #B14A75;
    border-radius: 25px;
    padding: 0 50px;
    color: #B14A75;
    display: flex;
    justify-content: center;
    align-items: center;

}
.center_top>img{
    height:30px;
    margin-left: 10px;
}
.fixedbottom{
    width: 100%;
    height: 75px;
    background: #fff;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.fixed_right{
    display: flex;
    align-items: center;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    width: calc(100% - 25px);
    justify-content: space-between;
    padding: 5px 10px;
}
.fixed_right_talk{
    display: flex;
    align-items: center;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    width: calc(100% - 25px);
    justify-content: center;
    padding: 10px 0px;
    /* 防止选中复制文字 */
    -webkit-user-select: none;
}
.fixed_right>img{
    width: 25px;
    height: 25px;
}
.microphone{
    width: 35px;
    height: 33px;
    margin-right: 10px;
}
.status-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 2px;
}
.answer{
    width: 100%;
    padding: 10px;
    display: flex;
}
.answer_left{
    width: 40px;
    height: 40px;
    background: #92A6FE;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.answer_left>img{
    width: 100%;
    height: 100%;
}
.answer_right{
    max-width: calc(95% - 50px);
}
.answer_right_text{
    color: #999;
    font-size: 15px;
}
.answer_right_box{
    line-height: 1.5;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    font-size: 1rem;
}
.tabbtns{
    width: 125px;
    text-align: center;
    height: 30px;
    background: #B14A75;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    border-radius: 5px;
}
.askqs{
    width: 100%;
    padding: 10px 10px;
    display: flex;
    justify-content: flex-end;
}
.askqs_right{
    width: 40px;
    height: 40px;
    background: #92A6FE;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.askqs_right>img{
    width: 100%;
    height: 100%;
}
.askqs_left_text{
    color: #999;
    font-size: 15px;
    text-align: right;
}
.askqs_left{
    max-width: calc(95% - 50px);
}
.askqs_left_box{
    line-height: 1.5;
    padding: 10px;
    border-radius: 10px;
    background-color: #6453D1;
    color: #fff;
    font-size: 1rem;
}
.rolelist{
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.rolelist_left{
    display: flex;
    width: 80%;
    height: 60px;
}
.rolelist_right{
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: center;
}
.rolelist_right>div{
    width: 80%;
    height: 25px;
    line-height: 25px;
    background: #B14A75;
    text-align: center;
    color: #fff;
    border-radius: 5px;
}
.disable{
    background: #9E9E9E !important;
}
.rolelist_left>.rolelist_left_iamge{
    width: 55px;
    height: 55px;
    background: #92A6FE;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.rolelist_left_iamge>img{
    width: 100%;
    height: 100%;
}
.rolelist_left_text{
    margin-left: 10px;
    width: 76%;
}
.rolelist_left_text_title{
    font-weight: 600;
    font-size: 17px;
}
.rolelist_left_text_slogan{
    color: #FFF;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.tags0{
    background: #7573E6;
    padding: 5px;
    border-radius: 10px;
}
.tags1{
    background: #F7B970;
    padding: 5px;
    border-radius: 10px;
}

.tags2{
    background: #A6D4AE;
    padding: 5px;
    border-radius: 10px;
}

</style>